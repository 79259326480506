import React from 'react'

import { MainLayout } from '../layouts/main'

export default function NotFoundPage() {
  return (
    <MainLayout>
      <p>page not found</p>
    </MainLayout>
  )
}
